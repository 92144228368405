import React, { useEffect } from "react"
import DaysBehind from "../../content/timeseries_days_behind.json"
import Countries from "../../content/timeseries_countries.json"
import DataAbsolute from "../../content/timeseries_absolute.json"
import DataRelative from "../../content/timeseries_relative.json"

import Layout from "../components/layout"
import { ResponsiveLine } from '@nivo/line'

import { Select, MenuItem } from '@material-ui/core';

const GraphPage = () => {

  const [country, setCountry] = React.useState('Belgium');
  const [gdata, setGdata] = React.useState(DataAbsolute.filter(DataAbsolute => DataAbsolute.id === 'Belgium'));
  const [scale, setScale] = React.useState('linear')
  const [dataType, setDataType] = React.useState('absolute');
  const [legend, setLegend] = React.useState('number of cases')
  const [markers, setMarkers] = React.useState([])

  useEffect(() => {
    async function refreshData() {
      if (dataType === 'absolute') {
        setGdata(DataAbsolute.filter(DataAbsolute => DataAbsolute.id === country))
        setLegend('number of cases')
        setMarkers([])
      } else if (dataType === 'daysbehind') {
        const array = DaysBehind.filter(function (elem) {
          //return false for the element that matches both the name and the id
          return (elem.id === country || elem.id === "Italy")
        });
        setGdata(array)
        setLegend('number of cases per 10000 habitants, days behind Italy')

        const daysBehind = -1 * (Countries[country])
        const daysBehindString = `${Countries[country]} days behind Italy`

        setMarkers([
          {
            axis: 'x',
            value: daysBehind,
            lineStyle: { stroke: '#b0413e', strokeWidth: 2 },
            legend: daysBehindString,
            legendOffsetX: -180,
            legendOrientation: 'horizontal',
          }
        ])

      } else {
        setGdata(DataRelative.filter(DataRelative => DataRelative.id === country))
        setLegend('number of cases per 10000 habitants')
        setMarkers([])
      }
    }

    refreshData(); // This is be executed when `loading` state changes
  }, [dataType, scale, country])

  const scales = {
    "log": {
      type: 'log',
      base: 10,
      max: 'auto',
    },
    linear: { type: 'linear', stacked: false, reverse: false }
  }

  const handleInputChange = event => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    if (event.target.name === "country") {
      setCountry(value)
    }
    if (event.target.name === "dataType") {
      setDataType(value)
    }
  }



  return (
    <Layout>
      <div style={{ maxWidth: `960px`, margin: `1.45rem` }}>

        <Select id="country" name="country" value={country} onChange={handleInputChange}>
          {Object.keys(Countries).map((data, index) => {
            return <MenuItem name="country" key={data} value={data}>{data}</MenuItem>
          })}
        </Select>

        <Select id="dataType" name="dataType" value={dataType} onChange={handleInputChange}>
          <MenuItem name="dataType" key="absolute" value="absolute">Absolute</MenuItem>
          <MenuItem name="dataType" key="relative" value="relative">Per 10000</MenuItem>
          <MenuItem name="dataType" key="daysbehind" value="daysbehind">Days behind Italy</MenuItem>
        </Select>
      </div>

      <div class="chart" style={{ maxWidth: `960px`, margin: `1.45rem` }}>
        <ResponsiveLine
          data={gdata}
          colors={{ scheme: 'category10' }}
          pointSize={5}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabel="y"
          pointLabelYOffset={-12}
          useMesh={true}
          curve="monotoneX"
          enableGridX={true}
          enableGridY={true}
          axisBottom={{
            orient: 'bottom',
            tickSize: 4,
            tickPadding: 5,
            tickRotation: 90,
            legend: 'date',
            legendOffset: 80,
            legendPosition: 'middle'
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: legend,
            legendOffset: -60,
            legendPosition: 'middle'
          }}
          margin={{ top: 50, right: 100, bottom: 100, left: 80 }}
          xScale={{ type: 'point' }}
          yScale={scales[scale]}
          axisTop={null}
          axisRight={null}
          markers={markers}
          legends={[
            {
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]
          }
        />
      </div>
    </Layout >
  )
}
export default GraphPage
